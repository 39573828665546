import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import { MainTitle, TitleWrapper } from './CommonElement';
import SharedCss from './SharedCss';

const SolutionWrapper = styled.div`
  padding: 50px 30px;

  /** Common Css **/
  ${SharedCss}

  /* PC */
  @media (min-width: 1024px) {
    padding-bottom: 120px;
  }
`;

const SolutionTitle = styled(TitleWrapper)`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      margin-bottom: 40px;
    }
  }
`;

const SolutionTitleJa = styled(MainTitle)`
  body & {
    font-size: 22px;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }
`;

const SolutionList = styled.div`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      max-width: 1024px;
      margin: 0 auto;
    }
  }
`;

const SolutionListItem = styled.div`
  body & {
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #0093bb;

    /* TAB */
    @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 40px 40px 40px 0;
    }

    /* PC */
    @media (min-width: 1024px) {
      padding: 60px 60px 30px 0;
      margin-bottom: 40px;
    }
  }
`;

const SolutionListItemTheme = styled.div`
  body & {
    /* TAB */
    @media (min-width: 768px) {
      width: 40%;
      padding: 0 10px;
    }

    /* PC */
    @media (min-width: 1024px) {
      width: 35%;
    }
  }
`;

const SolutionListItemThemeTitle = styled.h3`
  body & {
    font-size: 14px;
    font-weight: 300;

    /* PC */
    @media (min-width: 1024px) {
      font-size: 18px;
      line-height: 2;
    }
  }
`;

const SolutionListItemAnswer = styled.p`
  body & {
    font-size: 13px;
    color: #0093bb;

    /* TAB */
    @media (min-width: 768px) {
      width: 40%;
      padding: 0 10px;
      margin: 0;
    }

    /* PC */
    @media (min-width: 1024px) {
      font-size: 18px;
    }
  }
`;

const SolutionIcon = styled.img`
  display: block;
  width: 30%;
  margin: 0 auto 20px;

  @media (min-width: 768px) {
    width: 10%;
  }

  @media (min-width: 1024px) {
    width: 15%;
  }
`;

const WOS_DATA_QUERY = graphql`
  query SolutionData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-solution" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            mainTitle
            solutionList {
              iconImg {
                publicURL
              }
              iconAlt
              text1
              text2
            }
          }
        }
      }
    }
  }
`;

const Solution: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.SolutionDataQuery>(
    WOS_DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(data);

  return (
    <SolutionWrapper>
      <SolutionTitle>
        <SolutionTitleJa>{frontmatter?.mainTitle}</SolutionTitleJa>
      </SolutionTitle>
      <SolutionList>
        {frontmatter?.solutionList?.map((item, index) => (
          <SolutionListItem key={index}>
            <SolutionIcon src={item?.iconImg?.publicURL} />
            <SolutionListItemTheme>
              <SolutionListItemThemeTitle>
                {item?.text1}
              </SolutionListItemThemeTitle>
            </SolutionListItemTheme>
            <SolutionListItemAnswer>{item?.text2}</SolutionListItemAnswer>
          </SolutionListItem>
        ))}
      </SolutionList>
    </SolutionWrapper>
  );
};

export default Solution;
